// @formatter:off
body {
	// Sidenav
	--vex-sidenav-background: theme('colors.gray.900');
	--vex-sidenav-color: theme('colors.white');

	--vex-sidenav-toolbar-background: theme('colors.gray.900');
	--vex-sidenav-section-divider-color: theme('colors.white / 0.12');

	// Sidenav Item
	--vex-sidenav-item-background-hover: theme('colors.gray.950 / 30%');
	--vex-sidenav-item-background-active: theme('colors.gray.950 / 30%');
	--vex-sidenav-item-color: theme('colors.gray.300');
	--vex-sidenav-item-color-hover: theme('colors.white');
	--vex-sidenav-item-color-active: theme('colors.white');
	--vex-sidenav-item-icon-color: theme('colors.primary.400 / 60%');
	--vex-sidenav-item-icon-color-hover: theme('colors.primary.600');
	--vex-sidenav-item-icon-color-active: theme('colors.primary.600');
	--vex-sidenav-item-dropdown-background: var(--vex-sidenav-item-background-active);
	--vex-sidenav-item-dropdown-background-hover: theme('colors.gray.950 / 80%');
	--vex-sidenav-item-dropdown-color-hover: var(--vex-sidenav-item-color-hover);
	--vex-sidenav-item-dropdown-background-active: var(--vex-sidenav-item-dropdown-background-hover);
	--vex-sidenav-item-dropdown-color-active: var(--vex-sidenav-item-color-active);
	--vex-sidenav-item-ripple-color: theme('colors.white / 10%');
	--vex-sidenav-subheading-color: theme('colors.primary.300 / 80%');

	// Toolbar
	--vex-toolbar-background: theme('backgroundColor.foreground / 70%');
	--vex-toolbar-icon-color: rgb(var(--vex-color-primary-600));

	// Secondary Toolbar
	--vex-secondary-toolbar-background: var(--vex-toolbar-background);

	// Navigation
	--vex-navigation-color: theme('textColor.default');
	--vex-navigation-background: var(--vex-background-card);
}
// @formatter:on
