.vex-layout-ares {
	--vex-sidenav-background: var(--vex-background-background);
	--vex-toolbar-background: var(--vex-background-background);

	--vex-sidenav-color: theme('textColor.default');
	--vex-sidenav-section-divider-color: transparent;
	--vex-sidenav-item-background-hover: rgb(var(--vex-color-primary-600) / 0.1);
	--vex-sidenav-item-background-active: var(--vex-background-card);
	--vex-sidenav-item-color: theme('textColor.default');
	--vex-sidenav-item-color-hover: theme('textColor.default');
	--vex-sidenav-item-color-active: theme('textColor.default');
	--vex-sidenav-item-dropdown-color-active: var(--vex-sidenav-item-color-active);
	--vex-sidenav-item-dropdown-background-active: var(--vex-background-hover);

	--vex-sidenav-item-dropdown-background: var(--vex-background-background);
	--vex-sidenav-item-dropdown-background-hover: var(--vex-background-hover);

	--vex-secondary-toolbar-background: var(--vex-background-background);

	--vex-sidenav-toolbar-background: var(--vex-background-background);

	--vex-secondary-toolbar-height: 64px;

	.vex-sidenav {
		border: none;

		vex-sidenav-item {
			.vex-sidenav-item {
				width: unset;
				@apply rounded-full py-2 mx-4;

				&.vex-sidenav-item--active {
					@apply shadow;
				}
			}

			.item-level-0 > .vex-sidenav-item {
				@apply my-1;
			}

			.item-level-1 .vex-sidenav-item.vex-sidenav-item--active {
				@apply shadow-none font-medium;

				.vex-sidenav-item__label {
					@apply text-default;
				}
			}

			.item-level-1 .vex-sidenav-item:hover .vex-sidenav-item__label {
				@apply text-default;
			}
		}
	}

	.vex-layout-sidenav-collapsed .vex-sidenav vex-sidenav-item .vex-sidenav-item {
		@apply mx-0;
	}

	vex-toolbar {
		@apply border-b-0;
	}

	vex-secondary-toolbar {
		@apply rounded-b;

		.secondary-toolbar {
			@apply bg-transparent shadow-none border-t-0 border-b-0 -mb-6;
		}
	}

	vex-footer {
		@apply rounded-t overflow-hidden;
	}

	.vex-page-layout-header {
		background: var(--vex-background-background);
		color: theme('textColor.default');
	}

	&.vex-style-default {
		--vex-sidenav-item-background-active: theme('backgroundColor.primary.600');
		--vex-sidenav-item-color-active: theme('textColor.on-primary.600');
		--vex-sidenav-item-dropdown-color-active: var(--vex-sidenav-item-color);
		--vex-sidenav-item-dropdown-color-hover: theme('textColor.default');
		--vex-sidenav-item-icon-color-active: theme('textColor.on-primary.600');
	}
}
