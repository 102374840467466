:root {
	.dark .mat-mdc-menu-panel {
		@apply border-gray-600;
	}

	.mat-mdc-menu-panel {
		@apply rounded border border-gray-300;

		&.mat-menu-below {
			@apply mt-1;
		}

		&.mat-menu-above {
			@apply mb-1;
		}
	}

	.mat-mdc-menu-content {
		@apply p-2;
	}

	.mat-mdc-menu-item {
		@apply flex items-center rounded text-sm font-medium min-h-[40px] my-1 pl-2 pr-6 transition duration-100 ease-out;

		&:first-child {
			@apply mt-0;
		}

		&:last-child {
			@apply mb-0;
		}

		&:hover:not([disabled]) {
			@apply bg-primary-600/10;

			.mat-icon {
				@apply text-primary-600;
			}
		}

		.mat-icon {
			@apply icon-sm transition duration-100 ease-out;
		}
	}

	.mat-mdc-menu-item-submenu-trigger {
		@apply pr-12;
	}
}

.mat-mdc-menu-item .mat-icon-no-color {
	@apply text-current;
}
